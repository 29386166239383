var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "structure"
  }, [_c('b-modal', {
    attrs: {
      "id": "modal-edit-company",
      "title": "BootstrapVue",
      "centered": "",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function (_ref) {
        var close = _ref.close;
        return [_c('h5', [_vm._v("Название компании")]), _c('b-button', {
          staticClass: "close",
          on: {
            "click": function ($event) {
              return close();
            }
          }
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          }
        })])];
      }
    }])
  }, [_c('apollo-mutation', {
    attrs: {
      "mutation": require("../gql/updateCompanyName.gql"),
      "variables": {
        name: _vm.modal.name,
        id: _vm.user.company.id
      }
    },
    on: {
      "done": _vm.updaetCompanyNameDone
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var mutate = _ref2.mutate,
            loading = _ref2.loading;
        return [_c('form', {
          on: {
            "submit": function ($event) {
              $event.preventDefault();
              return mutate();
            }
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Введите название",
            "required": ""
          },
          model: {
            value: _vm.modal.name,
            callback: function ($$v) {
              _vm.$set(_vm.modal, "name", $$v);
            },
            expression: "modal.name"
          }
        }), _c('div', {
          staticClass: "modal-footer"
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "loading": loading,
            "disabled": _vm.user.company.name === _vm.modal.name
          },
          on: {
            "click": function ($event) {
              return mutate();
            }
          }
        }, [_vm._v("Сохранить")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": _vm.modal_close
          }
        }, [_vm._v(" Отмена ")])], 1)], 1)];
      }
    }])
  })], 1), _c('div', {
    staticClass: "structure-content"
  }, [_c('h1', {
    staticStyle: {
      "margin-bottom": "0"
    }
  }, [_vm._v(" Название: " + _vm._s(_vm.user.company.name) + " "), _c('img', {
    staticClass: "cursor",
    attrs: {
      "src": "/img/icons/pencil_edit.svg",
      "alt": "",
      "width": "16"
    },
    on: {
      "click": _vm.modal_open
    }
  })]), _c('div', {
    staticClass: "d-flex align-items-center mt-3"
  }, [_c('tags', {
    attrs: {
      "items": _vm.tags,
      "selected": _vm.selectedTag
    },
    on: {
      "select-tag": _vm.selectTag
    }
  }), _c('div', {
    staticClass: "btn-action ml-auto d-flex"
  }, [_vm.isBranches ? _c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "size": "m",
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.addGroupBranch
    }
  }, [_c('img', {
    staticClass: "mr",
    attrs: {
      "src": "/img/icons//settings/add_group.svg",
      "alt": ""
    }
  }), _vm._v(" Создать группу ")]) : _vm._e(), _c('b-button', {
    staticClass: "btn-primary cursor",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.add_store
    }
  }, [_c('img', {
    staticClass: "mr",
    attrs: {
      "src": "/img/icons/btn-plus.svg",
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.isBranches ? 'Добавить магазин' : 'Добавить юр. лицо') + " ")])], 1)], 1), _c('router-view')], 1), _c('add-group-branch-modal')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }