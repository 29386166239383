<template>
  <section class="structure">
    <b-modal
      id="modal-edit-company"
      title="BootstrapVue"
      centered
      hide-footer
    >
      <template #modal-header="{ close }">
        <h5>Название компании</h5>
        <b-button
          class="close"
          @click="close()"
        >
          <img
            src="/img/icons/modal_close.svg"
            alt=""
          />
        </b-button>
      </template>

      <apollo-mutation
        :mutation="require('../gql/updateCompanyName.gql')"
        :variables="{
          name: modal.name,
          id: user.company.id
        }"
        @done="updaetCompanyNameDone"
      >
        <template #default="{ mutate, loading }">
          <form @submit.prevent="mutate()">
            <b-form-input
              v-model="modal.name"
              type="text"
              placeholder="Введите название"
              required
            />

            <div class="modal-footer">
              <b-button
                variant="primary"
                :loading="loading"
                :disabled="user.company.name === modal.name"
                @click="mutate()"
                >Сохранить</b-button
              >
              <b-button
                variant="outline-primary"
                @click="modal_close"
              >
                Отмена
              </b-button>
            </div>
          </form>
        </template>
      </apollo-mutation>
    </b-modal>
    <div class="structure-content">
      <h1 style="margin-bottom: 0">
        Название: {{ user.company.name }}
        <img
          src="/img/icons/pencil_edit.svg"
          class="cursor"
          alt=""
          width="16"
          @click="modal_open"
        />
      </h1>
      <div class="d-flex align-items-center mt-3">
        <tags
          :items="tags"
          :selected="selectedTag"
          @select-tag="selectTag"
        />
        <div class="btn-action ml-auto d-flex">
          <b-button
            v-if="isBranches"
            size="m"
            variant="outline-primary"
            class="mr-3"
            @click="addGroupBranch"
          >
            <img
              src="/img/icons//settings/add_group.svg"
              class="mr"
              alt=""
            />
            Создать группу
          </b-button>
          <b-button
            variant="primary"
            class="btn-primary cursor"
            @click="add_store"
          >
            <img
              src="/img/icons/btn-plus.svg"
              class="mr"
              alt=""
            />
            {{ isBranches ? 'Добавить магазин' : 'Добавить юр. лицо' }}
          </b-button>
        </div>
      </div>
      <router-view />
    </div>

    <add-group-branch-modal />
  </section>
</template>

<script>
  import AddGroupBranchModal from '../modal/AddGroupBranchModal.vue'
  import { mapActions, mapGetters } from 'vuex'
  import Tags from '@/views/diagnostics/components/Tags.vue'
  import * as R from 'ramda'

  export default {
    components: { Tags, AddGroupBranchModal },
    data() {
      return {
        tags: [
          { id: 'branches', label: 'Структура' },
          { id: 'entities', label: 'Юр. лица' }
        ],
        selectedTag: 'branches',
        modal: {
          name: ''
        }
      }
    },
    computed: {
      ...mapGetters({
        branch_list: 'settings/getBranchList',
        user: 'core/getUserInfo'
      }),
      isBranches() {
        return !!this.$route.name.includes('branches')
      }
    },

    beforeMount() {
      this.setBreadcrumbs({ is_go_back: false })
    },

    methods: {
      ...mapActions({
        get_companies: 'settings/getCompanies',
        update_copmany: 'settings/updateCompnay',
        remove_company: 'settings/removeCompnay',
        setBreadcrumbs: 'breadcrumbs/set_current'
      }),

      toggle_filters() {},

      addGroupBranch() {
        this.$bvModal.show('add-group-branch-modal')
      },

      selectTag(id) {
        this.selectedTag = id
        this.$router.push({ name: 'company.' + id })
      },
      modal_open() {
        this.modal.name = this.user.company.name
        this.$bvModal.show('modal-edit-company')
      },
      add_store() {
        if (this.$route.name.includes('branches')) {
          this.$router.push({ name: 'company.create.branch' })
        } else {
          this.$router.push({ name: 'company.entities.new' })
        }
      },
      updaetCompanyNameDone({ data }) {
        const name = R.pathOr('', ['UpdateCompany', 'name'], data)
        this.update_copmany_name(name)
        this.modal_close()
      },

      modal_close() {
        this.$bvModal.hide('modal-edit-company')
      }
    }
  }
</script>

<style scoped>
  .structure {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
  }

  .structure-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
  }
</style>
